import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { colors, margins } from '../../components/Home/v2/StyledComponents';
import triangle from '../../assets/laRubrique/triangle.svg';
import sparkler from '../../assets/laRubrique/sparkler.png';

const Container = styled.div`
  width: 200px;
`;

const Triangle = styled.img`
  display: block;
  margin-bottom: ${margins.s};
`;

const Sparkler = styled.img`
  position: absolute;
  left: -50px;
  top: -5px;
  height: 30px;
  width: 30px;
`;

const LinkContainer = styled.div`
  position: relative;
  margin-bottom: ${margins.s};
`;

const linkStyle = {
  textDecoration: 'none',
  color: `${colors.navy}`,
};

const CategoriesSelector = ({ categories, selectedCategorySlug }) => (
  <Container>
    <Triangle
      src={triangle}
      alt="Inspirations pour retouches, broderies et transformations"
    />
    {categories.map(category => (
      <LinkContainer key={category.slug}>
        {(category.slug === selectedCategorySlug) &&
          <Sparkler src={sparkler} alt={`Vos inspirations pour une ${category.name}`} />
        }
        <Link
          to={category.path}
          style={linkStyle}
        >{category.name}<br /></Link>
      </LinkContainer>
    ))}
  </Container>
);

CategoriesSelector.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedCategorySlug: PropTypes.string,
};

CategoriesSelector.defaultProps = {
  selectedCategorySlug: null,
};

export default CategoriesSelector;
