import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container, Content, Title1 }
  from '../components/Home/homeStyledComponents';
import { colors, margins, fontSizes, mobileThresholdPixels, contentWidth }
  from '../tilli-components/src/components/styledComponents';
import MenuBar from '../components/Home/MenuBar';
import List from '../components/LaRubrique/List';
import CategoriesSelector from '../components/LaRubrique/CategoriesSelector';
import LaRubriqueFooter from '../components/LaRubrique/LaRubriqueFooter';
import Footer from '../components/Home/Footer';
import Layout from '../layouts/index';

const SubtitleContainer = styled.div`
  background-color: ${colors.lightGrey};
  padding: ${margins.m} 0px;
  margin-bottom: ${margins.l};
`;

const Subtitle = styled.h2`
  color: ${colors.navy};
  text-align: center;
  font-size: ${fontSizes.x2l};
  margin: 0px auto;
  max-width: 600px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.l};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: ${contentWidth - 200}px;
`;

const Text = styled.p`
  text-align: center;
  color: ${colors.navy};
  font-size: 12px;
`;

const ListTemplate = ({ pageContext: { posts, categories, category } }) => (
  <Layout
    routeSlug={(category && category.slug) ? `LaRubriqueCategory_${category.slug}` : 'LaRubrique'}
  >
    <Container>
      <Content>
        <MenuBar />
        <Title1>Rubrique Tilli</Title1>
        <SubtitleContainer>
          <Subtitle>
            {(category && category.title) || 'Trouvez des inspirations pour votre dressing'}
          </Subtitle>
        </SubtitleContainer>
        <Row>
          <CategoriesSelector
            categories={categories}
            selectedCategorySlug={category && category.slug}
          />
          <Column>
            {(category && category.text) && <Text>{category.text}</Text>}
            <List posts={posts} />
          </Column>
        </Row>
        <LaRubriqueFooter category={category} />
        <Footer />
      </Content>
    </Container>
  </Layout>
);


ListTemplate.propTypes = {
  pageContext: PropTypes.shape({
    posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    category: PropTypes.shape({}),
    categories: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default ListTemplate;
